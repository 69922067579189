import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../../components/layout/seo"
import Hero from "../../components/Global/secondLevelHero/secondLevelHero"
import FullWidthText from "../../components/Global/fullWidthText/fullWidthText"
import FullWidthCTA from "../../components/Global/fullWidthCta/fullWidthCta"
import FullWidthImage from "../../components/Global/fullWidthImage/fullWidthImage"
import Footnotes from "../../components/Global/footnotes/footnotes"
import Footer from "../../components/layout/footer"

const IndicationPage = ({ data }) => {
    const content = data.allContentfulIndicationPage.nodes[0];

    let heroData = {
      breadcrumbs: content.hero.breadcrumb,
      title: content.hero.title,
      content: content.hero.content,
      backgroundImage: content.hero.backgroundImage,
      backPos: "top right"
    }
    
    let graphTitleData = content.graphTitle
    let graphImageData = getImage(content.graph)
    let graphOutroData = content.graphOutro


    let pathwayImageData = getImage(content.pathway)
    let section2IntroData = content.section2Intro
    let section2OutroData = content.section2Outro

    let fullWidthCtaData = {
      title: content.relatedPages.title,
      buttons: content.relatedPages.buttons,
      customClass: "mt-5"
    }

    let fullWidthBannerData = {
      imageTitle: content.fullWidthBanner.image.title,
      image: getImage(content.fullWidthBanner.image),
    }
    
    let footnotesData = {
      pageJobCode: content.jobCodeDate,
      footnotesData: content.footerData,
    }

    return (
        <Layout>
            <Seo title="The QARZIBA® indication" />
            <Hero data={heroData}/>
            
            <GatsbyImage objectFit="contain" className="graph__column__3-10" image={graphImageData} />
            <FullWidthText data={graphOutroData} />
            <FullWidthText data={graphTitleData} />
            <GatsbyImage objectFit="contain" className="graph__column__3-10" image={pathwayImageData} />
            <FullWidthText data={section2OutroData} />
            <FullWidthText data={section2IntroData} />
            <FullWidthCTA data={fullWidthCtaData} />
            <FullWidthImage data={fullWidthBannerData} />
            <Footnotes data={footnotesData} />
            <Footer/>
        </Layout>
      )
}
export const query = graphql`
  query {
    allContentfulIndicationPage {
      nodes {
        hero {
          breadcrumb {
            title
            url
          }
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          backgroundImage {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }
        }
        graphTitle {
            childMarkdownRemark {
              html
            }
          }
          graph {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          graphOutro {
            childMarkdownRemark {
              html
            }
          }
          pathway {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          section2Outro {
            childMarkdownRemark {
              html
            }
          }
          section2Intro {
            childMarkdownRemark {
              html
            }
          }
          relatedPages {
            title {
              childMarkdownRemark {
                html
              }
            }
            buttons {
              buttonText {
                childMarkdownRemark {
                  html
                }
              }
              buttonUrl
            }
          }
          fullWidthBanner {
            image {
              gatsbyImageData(
                layout: FULL_WIDTH
              )
              title
            }         
          }
          footerData {
            title
            content {
              childMarkdownRemark {
                html
              }
            }
            id
          }
          jobCodeDate
        }
    }
  }
`
export default IndicationPage